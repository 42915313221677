// store.js

import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slice/authSlice';
import cameraReducer from './slice/cameraSlice'
import sfcTravelingExpencesReducer from './slice/sfcTravelingExpences'
import sfcFareAndAllowanceReducer from './slice/sfcFareAndAllowance'
import sfcSaveTravelExpenceReducer from './slice/sfcSaveTravelExpence'
import sfcFormFiltersReducer from './slice/sfcFormFilters'
import unReportedReducer from './slice/unReportedSlice'
import sfcDeleteExpenceReducer from './slice/sfcTravelExpenseDelete'
import sfcOnewayDistanceReducer from './slice/sfcOnewayDistance'
import sfcTravelExpenseFormReducer from './slice/sfcTravelExpenseForm'
import myExpenseReducer from './slice/myExpenseSlice'
import sfcUserBasicInfoReducer from './slice/sfcUserBasicInfo'
import sfcCreationSubmitReducer from './slice/sfcCreationSubmit'
import networkApiErrorReducer from './slice/networkAlertSlice'
import {homeExpenseReducer} from './slice/homeSlice'
import myExpenseDayWiseReducer from './slice/myExpenseDayWiseSlice'
import modifyRouteReducer from './slice/modifyRouteSlice'
import notificationInfoReducer from './slice/notification'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    camera: cameraReducer,
    sfcTravelingExpences: sfcTravelingExpencesReducer,
    sfcFareAndAllowance: sfcFareAndAllowanceReducer,
    sfcSaveTravelExpence: sfcSaveTravelExpenceReducer,
    sfcFormFilters: sfcFormFiltersReducer,
    unReported: unReportedReducer,
    myExpense: myExpenseReducer,
    homeExpense: homeExpenseReducer,
    sfcDeleteExpence: sfcDeleteExpenceReducer,
    sfcOnewayDistance: sfcOnewayDistanceReducer,
    sfcTravelExpenseForm: sfcTravelExpenseFormReducer,
    sfcUserBasicInfo: sfcUserBasicInfoReducer,
    sfcCreationSubmit: sfcCreationSubmitReducer,
    networkApiError: networkApiErrorReducer,
    myExpenseDayWise: myExpenseDayWiseReducer,
    modifyRoute: modifyRouteReducer,
    notificationInfo: notificationInfoReducer
  },
});