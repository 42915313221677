import React from 'react';
import { Link } from 'react-router-dom';
import homeIcon from '/assets/images/homeIcon.png';
import cameraScannerIcon from '/assets/images/cameraScannerIcon.png';
import notificationIcon from '/assets/images/notificationIcon.png';

const Footer = () => {
  return (
    <>
      <footer className="fixed bottom-0 w-full  text-white border-t-2 bg-white py-2 shadow-top">
        <div className="flex justify-around items-center h-16">
          <button className="footer-button">
            <img src={homeIcon} alt="Home" height={'25px'} width={'25px'} />
          </button>
          <Link to="/camera">
            <button className="footer-button">
              <img
                src={cameraScannerIcon}
                alt="camere-scanner"
                height={'50px'}
                width={'50px'}
              />
            </button>
          </Link>
         <Link to={'/notification'}>
         <button className="footer-button">
            <img
              src={notificationIcon}
              alt="notification"
              height={'32px'}
              width={'32px'}
            />
          </button>
         </Link>
        </div>
      </footer>
    </>
  );
};

export default Footer;
