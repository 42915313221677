import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axiosInstance';
import { errorResponseParser, successResponseParser } from '../../utils/handlers';


export const getFareAndAllowanceForDistance = createAsyncThunk(
  'getFareAndAllowanceForDistance',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`/SFCFareAndAllowanceForDistance`, {
        params,
      });
      return successResponseParser(res);
    } catch (error) {
      return rejectWithValue(errorResponseParser(error, dispatch));
    }
  }
);

export const getSfcAllowance = createAsyncThunk(
  'getSfcOneDistance',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`/SFCAllowance`, {
        params,
      });
      return successResponseParser(res);
    } catch (error) {
      return rejectWithValue(errorResponseParser(error, dispatch));
    }
  }
);

export const fetchSfcFareAndAllowance = createAsyncThunk(
  'fetchSfcFareAndAllowance',
  async (sfcForm1,{ rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`/SFCFareAndAllowance`, {
        params: {
          gradeId: 1,
          ...sfcForm1,
        },
      });
      return successResponseParser(res);
    } catch (error) {
      return rejectWithValue(errorResponseParser(error, dispatch));
    }
  }
);

export const sfcFareAndAllowance = createSlice({
  name: 'SfcFareAndAllowance',
  initialState: {
    isLoading: false,
    fareAndAllowance: null,
    allowance: null,
    apiError: '',
    allowanceValue: 0,
  },
  reducers: {
    updateAllowance: (state, action) => {
      state.allowanceValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSfcFareAndAllowance.pending, (state, action) => {
      state.isLoading = true;
      state.fareAndAllowance = null;
    });
    builder.addCase(fetchSfcFareAndAllowance.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fareAndAllowance = action.payload;
      state.apiError = false;
    });
    builder.addCase(fetchSfcFareAndAllowance.rejected, (state, action) => {
      state.isLoading = false;
      state.fareAndAllowance = null;
      state.apiError = 'Something is Wrong';
    });

    builder.addCase(getSfcAllowance.pending, (state, action) => {
      state.isLoading = true;
      state.allowance = null;
    });
    builder.addCase(getSfcAllowance.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allowance = action.payload;
      state.apiError = false;
    });
    builder.addCase(getSfcAllowance.rejected, (state, action) => {
      state.isLoading = false;
      state.allowance = null;
      state.apiError = 'Something is Wrong';
    });

    builder.addCase(getFareAndAllowanceForDistance.pending, (state, action) => {
      state.isLoading = true;
      state.fareAndAllowance = null;
    });
    builder.addCase(
      getFareAndAllowanceForDistance.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.fareAndAllowance = action.payload;
        state.apiError = false;
      }
    );
    builder.addCase(
      getFareAndAllowanceForDistance.rejected,
      (state, action) => {
        state.isLoading = false;
        state.allowance = null;
        state.apiError = 'Something is Wrong';
      }
    );
  },
});
export const { updateAllowance } = sfcFareAndAllowance.actions;
export default sfcFareAndAllowance.reducer;
