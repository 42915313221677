// src/Button.js
import React from 'react';

const Button = (props) => {
  const {
    label,
    type = 'button',
    onClick,
    btBgColor = 'white',
    btTextColor = 'text-primary',
    btFullwidth = false,
    btHeight = 'h-12',
    btWidth = 'w-52',
    borderWidth = 'border-[1px] border-primary',

  } = props;

  const buttonClasses = `
    ${btFullwidth ? 'w-96' : ''}
    ${btBgColor} ${btTextColor} ${btHeight} ${btWidth} ${borderWidth}
    rounded-3xl mb-2 font-medium text-lg flex items-center justify-center
  `

  return (
    <button
    style={{ backgroundColor: btBgColor }}
      className={buttonClasses}
      type={type}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default Button;
