import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import NotificationCardContainer from './NotificationCardContainer';
import expenseIcon from '../../assets/icons/expense-icon.png';
import callplanIcon from '../../assets/icons/callplan-icon.png';
import phoneIcon from '../../assets/icons/phone-icon.png';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { notificationDetails } from '../../redux/slice/notification';
import Spinner from '../../components/Spinner';

const Notification = () => {

  const dispatch = useDispatch();
  const { data: notification, isLoading} = useSelector((state) => state.notificationInfo);


  useEffect(() => {
    dispatch(notificationDetails())
  },[])


  return (
    isLoading ? <Spinner text='Loading'/> : <React.Fragment>
    <div className="flex m-8 justify-between">
      <button onClick={() => {window.history.back()}}>
      <FontAwesomeIcon icon={faAngleLeft} className='text-primary text-xl' />
      </button>
      <p className="text-primary font-semibold text-xl">Notification</p>
      <div></div>
    </div>
    <div className='px-5'>
      <div className='flex flex-col'>
        {notification?.notificationMessage?.length > 0 ? notification?.notificationMessage?.map((val, index) => {
          return (
            <NotificationCardContainer
              key={index}
              text={val?.notificationMessage}
              icon={val?.notificationMessage.toLowerCase().startsWith("expense") ? expenseIcon : phoneIcon}
              date={'23/01/2024'}
            />
          );
        }) : <div className='flex justify-center items-center text-primary'>No Notifications</div>}
      </div>
    </div>
  </React.Fragment>
  );
};

export default Notification;
