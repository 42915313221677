
export const baseURL = import.meta.env.VITE_BASE_URL;
export const priodSatate = [
  { periodName: 'Full Day', value: '1' },
  { periodName: 'First Half Day', value: '2' },
  { periodName: 'Second Half Day', value: '3' },
];

let newData = JSON.parse(localStorage.getItem('userData'));
export const userInfo = {
  employeeId: newData?.employeeId,
  companyName: newData?.companyName,
  designation: newData?.designation,
  employeeName: newData?.employeeName,
  token: newData?.token,
  useName: newData?.useName,
  companyId: newData?.companyId,
  month: newData?.expenseMonth,
  year: newData?.expenseYear,
};