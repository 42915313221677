import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axiosInstance';
import { errorResponseParser, successResponseParser } from '../../utils/handlers';




export const sfcUserDetails = createAsyncThunk(
  'sfcUserDetails',
  async (_,{ rejectWithValue, dispatch}) => {
    try {
      const employeeId = JSON.parse(localStorage.getItem("userData"))?.employeeId
      const res = await axios.get(`/SFCEmployeeDetails`, {
        params: {employeeId: employeeId},
      });
      return successResponseParser(res)
     } catch (error) {
      return rejectWithValue(errorResponseParser(error,dispatch))
     }
  }
);

export const sfcUserBasicInfo = createSlice({
  name: 'sfcUserBasicInfo',
  initialState: {
    isLoading: false,
    data: null,
    areaRange: null,
    apiError: '',
  },
  extraReducers: (builder) => {
    builder.addCase(sfcUserDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(sfcUserDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.areaRange = action.payload.areaRange;
    });
    builder.addCase(sfcUserDetails.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.data = null;
    });
  },
});

export default sfcUserBasicInfo.reducer;
