import React, { useEffect, useState } from 'react';
import SfcFormOne from './SfcFormOne';
import SfcFormTwo from './SfcFormTwo';
import SfcFormThree from './SfcFormThree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSfcFareAndAllowance,
  getFareAndAllowanceForDistance,
  getSfcAllowance,
  updateAllowance,
} from '../../redux/slice/sfcFareAndAllowance';
import {
  clearState,
  postSfcSaveTravelExpence,
  updateSfcTravelExpense,
} from '../../redux/slice/sfcSaveTravelExpence';
import { getSfcCities } from '../../redux/slice/sfcFormFilters';
import { sfcUserDetails } from '../../redux/slice/sfcUserBasicInfo';
import {
  getSfcTravelExpenseFormInitialState,
  upadateExpenseIdCreationFormData,
} from '../../redux/slice/sfcTravelExpenseForm';
import ErrorsPopup from './ErrorsPopup';
import Spinner from '../../components/Spinner';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const validateSfcForm = ({
  fromTerritoryId,
  toTerritoryId,
  fromCityId,
  toCityId,
  categoryId,
  transportId,
  effectiveDate,
}) => {
  const errors = {};
  if (!fromTerritoryId) errors.fromTerritoryId = 'Please select';
  if (!toTerritoryId) errors.toTerritoryId = 'Please select';
  if (!fromCityId) errors.fromCityId = 'Please select';
  if (!toCityId) errors.toCityId = 'Please select';
  if (!categoryId) errors.categoryId = 'Please select';
  if (!transportId) errors.transportId = 'Please select';
  if (!effectiveDate) errors.effectiveDate = 'select date';
  return [
    !!fromTerritoryId &&
      !!toTerritoryId &&
      !!fromCityId &&
      !!toCityId &&
      !!categoryId &&
      !!transportId &&
      !!effectiveDate,
    errors,
  ];
  // !!effectiveDate]
};

const sfcResponseProcesser = (data) => ({
  oneWayDistance: data.oneWayDistance,
  twoWayDistance: data.twoWayDistance,
  oneWayFare: data.oneWayFare,
  twoWayFare: data.twoWayFare,
  allowance: data.allowance,
  specification: data.specification,
  total: data.total,
});

const formatDate = (date) => {
  const [d1, d2, d3] = date.split('-');
  return d1.length === 4 ? `${d3}-${d2}-${d1}` : date;
};

const isSameSfcFareAndAllowance = (previous, present) => {
  return (
    previous.fromTerritoryId !== present.fromTerritoryId ||
    previous.toTerritoryId !== present.toTerritoryId ||
    previous.fromCityId !== present.fromCityId ||
    previous.toCityId !== present.toCityId ||
    previous.categoryId !== present.categoryId ||
    previous.transportId !== present.transportId
  );
};

const getInvalidDataMessage = (data, user) => {
  if (user.areaRange === 'T') {
    let errorCount = 0;
    let message = 'Please Enter ';
    if (!data.numberOfDoctors) {
      errorCount++;
      message += 'number of Doctors';
    }
    if (!data.numberOfChemist) {
      errorCount++;
      message += errorCount ? ' and number of chemists' : ' number of chemists';
    }
    if (errorCount) {
      return message;
    }
  }
  if (data.fromCityId === data.toCityId && data.categoryId !== 1) {
    return 'Same from city and to city allowed only for HQ Categery.';
  }
  if (data.categoryId === 1 && data.fromCityId !== data.toCityId) {
    return 'For HQ Category from city and to city should be same.';
  }
  return '';
};

const getFareAndAllowanceForDistanceResponseProcessor = (data, allowance) => {
  return {
    oneWayDistance: data.oneWayDistance,
    twoWayDistance: data.twoWayDistance,
    oneWayFare: data.oneWayFare,
    twoWayFare: data.twoWayFare,
    total: allowance + data.twoWayFare,
  };
};

const FormContainer = ({ onBack }) => {
  const [isOneWayDistanceEdited, setIsOneWayDistanceEdited] = useState(false);
  const [validationError, setValidationError] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const categories = useSelector((state) => state.sfcFormFilters.categories);
  const dispatch = useDispatch();
  const { isLoading, fareAndAllowance, allowance } = useSelector(
    (state) => state.sfcFareAndAllowance
  );

  const { expenseIdCreationFormData, isEditingMode } = useSelector(
    (state) => state.sfcTravelExpenseForm
  );
  const { areaRange: employeeType, data: userInfo } = useSelector(
    (state) => state.sfcUserBasicInfo
  );

  const [activeForm, setActiveForm] = useState(1);

  const onBackHandler = () => {
    if (activeForm === 1) onBack && onBack();
    setActiveForm((prev) => (prev <= 1 ? 1 : prev - 1));
    setIsOneWayDistanceEdited(false);
  };

  const onNextHandler = () => {
    if (isOneWayDistanceEdited && userInfo) {
      setIsOneWayDistanceEdited(false);
      dispatch(
        getFareAndAllowanceForDistance({
          employeeId: userInfo.employeeId,
          oneWayDistance: expenseIdCreationFormData.oneWayDistance,
          effectiveDate: expenseIdCreationFormData.effectiveDate,
        })
      );
      dispatch(
        getSfcAllowance({
          employeeId: userInfo.employeeId,
          cityId: expenseIdCreationFormData.toCityId,
          effectiveDate: expenseIdCreationFormData.effectiveDate,
        })
      );
      return;
    }
    const [validate, errors] = validateSfcForm(expenseIdCreationFormData);
    if (!validate) {
      setValidationError(errors);
      return;
    }

    if (activeForm === 1) {
      const message = getInvalidDataMessage(
        expenseIdCreationFormData,
        userInfo
      );
      if (message) {
        setErrorMessage(message);
        return;
      }
    }

    // if (
    //   activeForm === 2 &&
    //   expenseIdCreationFormData.categoryId !== 1 &&
    //   expenseIdCreationFormData.oneWayDistance === 0
    // ) {
    //   setValidationError((prev) => ({
    //     ...prev,
    //     oneWayDistance: 'please enter distance',
    //   }));
    //   return;
    // }
    setActiveForm(
      (prev) => (prev === 3 ? prev : prev + 1)
      // prev === 3 ? prev : prev === 1 ? (validate ? prev + 1 : prev) : prev + 1
    );

    if (activeForm === 1) {
      if (fareAndAllowance === null && validate && userInfo) {
        dispatch(
          fetchSfcFareAndAllowance({
            ...expenseIdCreationFormData,
            employeeId: userInfo.employeeId,
            effectiveDate:
              userInfo.effectiveFromDate ||
              expenseIdCreationFormData.effectiveDate,
            companyId: userInfo?.companyId,
          })
        );
      }
      if (allowance === null && validate && !isEditingMode && userInfo) {
        dispatch(
          getSfcAllowance({
            employeeId: userInfo.employeeId,
            cityId: expenseIdCreationFormData.toCityId,
            effectiveDate: expenseIdCreationFormData.effectiveDate,
          })
        );
      }
      if (
        validate &&
        fareAndAllowance &&
        isSameSfcFareAndAllowance(
          fareAndAllowance,
          expenseIdCreationFormData
        ) &&
        userInfo
      ) {
        dispatch(
          fetchSfcFareAndAllowance({
            ...expenseIdCreationFormData,
            employeeId: userInfo.employeeId,
            effectiveDate:
              userInfo.effectiveFromDate ||
              expenseIdCreationFormData.effectiveDate,
            companyId: userInfo?.companyId,
          })
        );
      }
      if (
        validate &&
        allowance &&
        isSameSfcFareAndAllowance(
          fareAndAllowance,
          expenseIdCreationFormData
        ) &&
        userInfo
      ) {
        dispatch(
          getSfcAllowance({
            employeeId: userInfo.employeeId,
            cityId: expenseIdCreationFormData.toCityId,
            effectiveDate: expenseIdCreationFormData.effectiveDate,
          })
        );
      }
      return;
    }
    if (activeForm === 3 && userInfo) {
      const { employeeId, companyId, stpfcId } = userInfo;
      const formData = {
        ...expenseIdCreationFormData,
        employeeId,
        companyId,
        // companyId,
        stpfcId,
        isFareAuto: 0,
        status: 'D',
        specification: 'spec',
        // remarks: 'remark',
        // companyId: 25,
      };

      isEditingMode
        ? dispatch(updateSfcTravelExpense(formData))
        : dispatch(postSfcSaveTravelExpence(formData));
      return;
    }
  };

  const onChangeHandler = ({ target: { name, value } }) => {
    let inputVal = value;
    if (name === 'effectiveDate') inputVal = formatDate(value);
    if (name === 'oneWayDistance') setIsOneWayDistanceEdited(true);
    dispatch(upadateExpenseIdCreationFormData({ [name]: inputVal }));
    setValidationError({ ...validationError, [name]: '' });
  };


  useEffect(() => {
    if (fareAndAllowance && allowance && categories) {
      const allowanceValue =
        allowance[
          Object.keys(allowance).find((attr) =>
            attr.toLowerCase().startsWith(
              categories
                .find(
                  (cat) =>
                    cat.categoryId ===
                    Number(expenseIdCreationFormData.categoryId)
                )
                ?.categoryName.slice(0, 2)
                .toLowerCase()
            )
          )
        ];
      dispatch(updateAllowance(allowanceValue));

      dispatch(
        upadateExpenseIdCreationFormData({
          ...sfcResponseProcesser(
            getFareAndAllowanceForDistanceResponseProcessor(
              fareAndAllowance,
              allowanceValue
            )
          ),
          allowance: allowanceValue,
        })
      );
    }
  }, [fareAndAllowance, allowance, isLoading]);

  useEffect(() => {
    if (expenseIdCreationFormData.fromTerritoryId) {
      dispatch(getSfcCities(expenseIdCreationFormData.fromTerritoryId));
    }
  }, [expenseIdCreationFormData.fromTerritoryId]);

  useEffect(() => {
    dispatch(clearState());
  }, []);

  return (
    <div className="h-lvh w-lvw flex flex-col justify-between">
      <ErrorsPopup
        show={!!errorMessage}
        message={errorMessage}
        onClose={() => setErrorMessage('')}
      />
      <div>
        <div className="flex  p-8 justify-between">
          <button onClick={onBackHandler}>
            <FontAwesomeIcon icon={faAngleLeft} size="lg" color="#A04D95" />
          </button>
          <p className="text-primary font-semibold text-xl">Add New</p>
          <div></div>
        </div>

        <div>
          <div className="max-w-2xl mx-auto w-[80vw] flex items-center font-semibold">
            <div
              className={`bg-primary border-2 border-primary text-white min-w-[36px] h-[36px] flex items-center justify-center rounded-full`}
            >
              1
            </div>
            <div
              className={`my-lg:w-32  md:w-80 h-1 bg-${
                activeForm > 1 ? 'primary' : 'slt-color'
              }`}
            ></div>
            <div
              className={`${
                activeForm >= 2 && 'bg-primary'
              } border-2 border-primary text-${
                activeForm >= 2 ? 'white' : 'black'
              } min-w-[36px] h-[36px] flex items-center justify-center rounded-full`}
            >
              <span>2</span>
            </div>
            <div
              className={`my-lg:w-32 md:w-80 h-1 bg-${
                activeForm > 2 ? 'primary' : 'slt-color'
              }`}
            ></div>
            <div
              className={`${
                activeForm >= 3 && 'bg-primary'
              } border-2 border-primary text-${
                activeForm >= 3 ? 'white' : 'black'
              } min-w-[36px] h-[36px] flex items-center justify-center rounded-full`}
            >
              <span>3</span>
            </div>
          </div>
        </div>
        <div className="mt-8 mx-3">
          {activeForm === 1 ? (
            <SfcFormOne
              errors={validationError}
              onChangeHandler={onChangeHandler}
              formValues={expenseIdCreationFormData}
            />
          ) : activeForm === 2 ? (
            <SfcFormTwo
              // errors={validationError}
              loading={isLoading}
              onChangeHandler={onChangeHandler}
              formValues={expenseIdCreationFormData}
              // disableOneway={
              //   Number(expenseIdCreationFormData.categoryId) === 1 ||
              //   (Boolean(fareAndAllowance) &&
              //     fareAndAllowance.oneWayDistance !== 0)
              // }
            />
          ) : activeForm === 3 ? (
            <SfcFormThree
              onChangeHandler={onChangeHandler}
              formValues={expenseIdCreationFormData}
              goToHomePage={onBack}
              goToFormOne={() => setActiveForm(1)}
            />
          ) : null}
        </div>
      </div>

      <div>
        <div className="flex justify-end p-5 shadow-top">
          <button
            className="w-[171px] bg-primary text-white rounded-full p-3 font-bold"
            onClick={onNextHandler}
          >
            {isEditingMode && activeForm === 3
              ? 'Update'
              : activeForm === 3
              ? 'Add'
              : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormContainer;
