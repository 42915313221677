import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';

const SuccessfullPopup = ({ duration = 2, onClose, displayText}) => {
    const timerId = useRef(null)

    useEffect(() => {
        timerId.current = setTimeout(() => {
            onClose()
        }, duration * 1000)
            return () => timerId.current && clearTimeout(timerId.current)
    }, [])

  return (<div className="absolute w-full h-full bg-disable z-30 flex justify-center items-center">
     <div className="flex flex-col justify-center items-center gap-5 text-[4rem] text-primary m-5 bg-white h-[282px] w-[282px] rounded-xl">
        <FontAwesomeIcon icon={faCircleCheck} />
        <p className="text-primary text-fieldset font-medium">{displayText}</p>
      </div>
    </div> )
};

export default SuccessfullPopup;