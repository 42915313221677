import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SplashScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/login');
    }, 2000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="bg-l-primary absolute left-0 top-0  w-screen h-screen flex flex-col gap-1 justify-center items-center">
      <div className="text-white text-5xl font-bold">Kea</div>
      <span className="text-white text-2xl">expense</span>
    </div>
  );
};

export default SplashScreen;
