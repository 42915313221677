import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../axiosInstance'

export const setUserInfo = (userInfo) => ({
  type: 'SET_USER_INFO',
  payload: userInfo,
});


// login user
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/Login`, data);
      // console.log('response//', response.data);
      if(response.data === null) {
        return rejectWithValue('Invalid Credentials');
      }
      localStorage.setItem('userData', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    userInfo: JSON.parse(localStorage.getItem('userData')) || {},
    isAuthenticated: false,
    apiError: false,
    errorMessage: '',
    token: '',
  },
  reducers: {
    logout: (state) => {
      state.userInfo = null;
      state.isAuthenticated = false;
      localStorage.removeItem('userData');
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userInfo = action.payload;
        state.isAuthenticated = true;
        state.apiError = false;
        state.errorMessage = '';
        state.token = action.payload.token;
        // console.log('userData', action.payload);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.apiError = true;
        state.errorMessage = action.payload;
      });
  },
});

export const { logout } = authSlice.actions;
export const getUserInfo = (state) => state.auth;

export default authSlice.reducer;
