import React from 'react';

const NotificationCardContainer = ({ text, icon, date }) => {
  return (
    <>
      <div className="flex items-center gap-5 py-5 bg-white">
        <div className="p-3 rounded-full border border-secondary-200">
          <img src={icon} alt="expense-icon" width={'20px'} height={'20px'} />
        </div>
        <div className="flex flex-col gap-2">
          <div className="font-normal">{text}</div>
          <div className="text-secondary-200 text-sm">
            <p>{date}</p>
          </div>
        </div>
      </div>
      <div className="h-[1px] bg-gray-300 "></div>
    </>
  );
};

export default NotificationCardContainer;
