import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

const isLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400;
};

const getNumberDaysInMonth = (month, isleap) => {
  switch (month) {
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      return 31;
    case 2:
      return isleap ? 29 : 28;
    default:
      return 30;
  }
};

const isToday = (date, month, year) => {
  const getZero = (num) => (num > 9 ? num : '0' + num);
  const currentDate = new Date();
  const cDate = getZero(currentDate.getDate());
  const cMonth = getZero(currentDate.getMonth() + 1);
  const cYear = currentDate.getFullYear();
  return (
    `${cDate}/${cMonth}/${cYear}` ===
    `${getZero(date)}/${getZero(month)}/${year}`
  );
};

const getMonthDetails = ({ monthId, year }) => {
  const totalNumberOfDays = getNumberDaysInMonth(monthId, isLeapYear(year));
  const days = new Array(totalNumberOfDays).fill('');
  const startDay = new Date(
    `01/${monthId > 9 ? monthId : '0' + monthId}/${year}`
  ).getDay();
  let date = 1;
  for (let i = startDay; i <= Infinity; i++) {
    days[i] = date <= totalNumberOfDays ? '' + date++ : '';
    if (date - 1 === totalNumberOfDays && days.length % 7 === 0) return days;
  }
  return days;
};

const weekDays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
const months = [
  { monthId: 1, monthName: 'January' },
  { monthId: 2, monthName: 'February' },
  { monthId: 3, monthName: 'March' },
  { monthId: 4, monthName: 'April' },
  { monthId: 5, monthName: 'May' },
  { monthId: 6, monthName: 'June' },
  { monthId: 7, monthName: 'July' },
  { monthId: 8, monthName: 'August' },
  { monthId: 9, monthName: 'September' },
  { monthId: 10, monthName: 'October' },
  { monthId: 11, monthName: 'November' },
  { monthId: 12, monthName: 'December' },
];

const Calender = ({ onSelect }) => {
  const [dayDetails, setDayDetailes] = useState([]);
  const [monthYear, setMonthYear] = useState({
    monthId: 6,
    monthName: 'June',
    year: 2024,
  });
  const onMonthYearChangeHandler = (val) => {
    if (val > 0) {
      if (monthYear.monthId === 12) {
        monthYear.year = monthYear.year + 1;
        setMonthYear((prev) => ({ ...prev, ...months[0] }));
      } else {
        setMonthYear((prev) => ({
          ...prev,
          ...months.find((month) => month.monthId === monthYear.monthId + 1),
        }));
      }
      setMonthYear((prev) => ({ ...prev }));
    } else {
      if (monthYear.monthId === 1) {
        monthYear.year = monthYear.year - 1;
        setMonthYear((prev) => ({ ...prev, ...months[11] }));
      } else {
        setMonthYear((prev) => ({
          ...prev,
          ...months.find((month) => month.monthId === monthYear.monthId - 1),
        }));
      }
    }
  };
  useEffect(() => {
    const month = { ...months[new Date().getMonth()] };
    month.year = new Date().getFullYear();
    setMonthYear(month);
  }, []);

  useEffect(() => {
    setDayDetailes(getMonthDetails(monthYear));
  }, [monthYear]);
  return (
    <div className="my-2 flex flex-col gap-2">
      <div className="flex items-center justify-center gap-4 mb-4">
        <FontAwesomeIcon
          icon={faAngleLeft}
          className="text-xl text-primary"
          onClick={onMonthYearChangeHandler.bind(this, -1)}
        />
        <p className="text-lg w-1/2 text-center text-secondary-400 font-bold">
          {monthYear.monthName} {monthYear.year}
        </p>
        <FontAwesomeIcon
          icon={faAngleRight}
          className="text-xl text-primary"
          onClick={onMonthYearChangeHandler.bind(this, 1)}
        />
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex">
          {weekDays.map((day) => {
            return (
              <p
                key={day}
                className="grow font-semibold text-secondary-400 text-center"
              >
                {day}
              </p>
            );
          })}
        </div>
        {new Array(dayDetails.length / 7).fill('').map((week, weekIndex) => {
          return (
            <div className="grid grid-cols-7 gap-1">
              {dayDetails
                .slice(weekIndex * 7, weekIndex * 7 + 7)
                .map((date, index) => {
                  return (
                    <div
                      className={`grow text-center round p-3 rounded-full font-semibold ${
                        isToday(date, monthYear.monthId, monthYear.year)
                          ? 'text-white bg-primary'
                          : 'text-secondary-400'
                      } `}
                    >
                      {date}
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Calender;
