// CustomDatePicker.jsx
import React, { useCallback } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = ({ selectedDate, onChange, placeholder = 'Select Date' }) => {

  const handleDateChange = useCallback(
    (date) => {
      if (onChange) onChange(date);
    },
    [onChange]
  );

  return (
    <div className="relative left-0 pt-2 text-center" style={{ width: '13.7rem' }}>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="dd-MM-yyyy"
        placeholderText={placeholder}
        className="bg-[#f5f5f5] rounded-md text-right text-[#545454] font-normal placeholder:text-[12px]" 
        popperPlacement="bottom"
        popperModifiers={{
          offset: {
            enabled: true,
            offset: '0,10px',
          },
        }}
      />
      <div className="absolute top-1/2 right-3 transform -translate-y-1/2 text-[#545454]">
        <i className="fa fa-calendar"></i>
      </div>
    </div>
  );
};

export default CustomDatePicker;
