import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axiosInstance';
import {
  errorResponseParser,
  successResponseParser,
} from '../../utils/handlers';

export const notificationDetails = createAsyncThunk(
  'notificationDetails',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const employeeId = JSON.parse(
        localStorage.getItem('userData')
      )?.employeeId;

      const res = await axios.get(`/getNotification`, {
        params: { employeeId: employeeId },
      });
      return successResponseParser(res);
    } catch (error) {
      return rejectWithValue(errorResponseParser(error, dispatch));
    }
  }
);

export const notificationInfo = createSlice({
  name: 'notificationInfo',
  initialState: {
    isLoading: false,
    data: null,
    apiError: '',
  },
  extraReducers: (builder) => {
    builder.addCase(notificationDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(notificationDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(notificationDetails.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.data = null;
    });
  },
});

export default notificationInfo.reducer;
