import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axiosInstance';
import { errorResponseParser, successResponseParser } from '../../utils/handlers';


const employeeId = JSON.parse(localStorage.getItem("userData"))?.employeeId

const defaultParams = {
  employeeId:employeeId,
  sequenceNo: 1,
};
export const sfcTravelExpenceDelete = createAsyncThunk(
  'sfcTravelExpenceDelete',
  async (data,{rejectWithValue,dispatch}) => {
    try {
      const res = await axios.delete(`/SFCdelete`, {
        params: { ...data, ...defaultParams },
      });
      return successResponseParser(res)
     } catch (error) {
      return rejectWithValue(errorResponseParser(error,dispatch))
     }
  }
);

export const sfcDeleteExpence = createSlice({
  name: 'sfcDeleteExpence',
  initialState: {
    isLoading: false,
    data: null,
    apiError: '',
    isSuccess: false
  },
  reducers: {
    getInitialDeleteExpense: (state) => {
      state.isLoading = false,
      state.data =  null,
      state.apiError =  '',
      state.isSuccess =  false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(sfcTravelExpenceDelete.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false
    });

    builder.addCase(sfcTravelExpenceDelete.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = true
    });
    builder.addCase(sfcTravelExpenceDelete.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.data = null;
      state.isSuccess = false
    });
  },
});
export const { getInitialDeleteExpense } = sfcDeleteExpence.actions
export default sfcDeleteExpence.reducer;
