import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUnReportedData } from '../../redux/slice/unReportedSlice';
import Dropdown from '../../components/dropdown/Dropdown';
import Button from '../../components/button/Button';
import { useNavigate } from 'react-router-dom';
import {
  fetchExpenseType,
  getAllExpenseType,
  getCapturedImage,
  setExpenseSubmit,
  getExpenseSubmitResponse,
} from '../../redux/slice/cameraSlice';
import PopupModal from '../../components/modal/PopupModal';
import CustomDatePicker from '../../components/customDatePicker/CustomDatePicker.jsx';

import { userInfo } from '../../utils/Constant';
const { employeeId, companyId } = userInfo;

// images
import EditIcon from '/assets/images/editPrimaryColorIcon.png';

const CapturedDetails = () => {
  const [isEdit, setEdit] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({
    todayDate: '',
    total: '',
    desc: '',
    invoiceNo: '',
    expenseType: '',
    expenseOrSundry: '',
    amount: '',
  });
  const [errors, setErrors] = useState({});

  const allExpenseType = useSelector(getAllExpenseType);
  const capturedImage = useSelector(getCapturedImage);
  const { isLoading, expenseSubmitResponse, apiError, errorMessage } =
    useSelector(getExpenseSubmitResponse);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchExpenseType());
  }, [dispatch]);

  const toggleEditInvoice = () => setEdit((prev) => !prev);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoiceDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' })); // Clear error on input change
  };

  const handleExpenseTypeChange = (data) => {
    let expenseOrSundry = allExpenseType
      .filter((item) => item.expense_Type === data.expense_Type)
      .map((item) => item.expenseOrSundry)
      .join('');
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      expenseOrSundry,
      expenseType: data.expense_Type,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, expenseType: '' }));
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
  };

  const handleDateChange = (newDate) => {
    setInvoiceDetails((prevDetails) => ({
      ...prevDetails,
      todayDate: newDate,
    }));
  };

  const handleNavigate = (path) => navigate(path);

  // Handle submit
  const handleSaveClick = async () => {
    const {
      todayDate: UploadDate,
      desc,
      invoiceNo: InvoiceNumber,
      expenseType: ExpenseType,
      expenseOrSundry,
      amount,
    } = invoiceDetails;

    // Validation check
    const newErrors = {};
    if (!ExpenseType) newErrors.expenseType = 'Please select an expense type';
    if (!InvoiceNumber) newErrors.invoiceNo = 'Please enter an invoice number';
    if (!UploadDate) newErrors.todayDate = 'Please select an invoice date';
    if (!amount) newErrors.amount = 'Please enter an amount';

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    const formData = {
      UploadDate,
      fare: amount,
      desc,
      InvoiceNumber,
      ExpenseType,
      EmployeeId: employeeId,
      CompanyId: companyId,
      ImageBase64: capturedImage,
      InvoiceDate: UploadDate,
      expenseOrSundry,
    };

    try {
      const response = await dispatch(setExpenseSubmit(formData));
      await dispatch(fetchUnReportedData());

      if (!response.error) {
        setShowSuccessModal(true);
        setTimeout(() => {
          navigate('/un-reported-expenses');
        }, 1000);
      } else {
        setShowSuccessModal(true);
      }
    } catch (error) {
      console.error('Error submitting expense:', error);
      setShowSuccessModal(true);
    }
  };

  return (
    <div className="flex flex-col relative pt-8 w-screen h-screen">
      {/* Image Preview */}
      <div className="w-full flex justify-center p-4">
        <img
          src={capturedImage}
          alt="Captured"
          className="rounded-lg"
          style={{ width: '50vw', height: '30vh' }}
        />
      </div>

      <div className="w-full flex justify-between mt-4 px-4 items-center">
        <p className="text-base font-semibold">Expense Type</p>
        <Dropdown
          label="Expense Type"
          name="expense_Type"
          value={invoiceDetails.expenseType}
          options={allExpenseType}
          attributeValue="expense_Type"
          attributeName="expense_Type"
          onChange={handleExpenseTypeChange}
          className={errors.expenseType ? 'border border-red-500' : ''}
        />
      </div>
      {errors.expenseType && (
        <p className="text-red-500 text-xs text-right pt-2 pr-4">
          {errors.expenseType}
        </p>
      )}

      {/* Invoice Details */}
      <div className="w-full space-y-2 mt-4 px-4">
        <div>
          <p className="text-base font-semibold">Invoice Details</p>
        </div>
        <div className="bg-[#F3F3F3] p-4 rounded-lg space-y-1">
          <div className="flex">
            <label className="w-1/3 text-baseSm text-primary-black font-medium">
              Invoice No
            </label>
            <input
              type="text"
              placeholder="_ _ _ _ _ _ _"
              name="invoiceNo"
              value={invoiceDetails.invoiceNo}
              onChange={handleInputChange}
              className={`w-2/3 py-1 bg-[#F3F3F3] text-end text-[#545454] font-normal ${
                errors.invoiceNo ? 'border border-red-500' : ''
              }`}
              readOnly={!isEdit}
              style={{ backgroundColor: '#F3F3F3', color: '#545454' }}
            />
          </div>

          <div className="flex">
            <label className="w-full text-baseSm text-primary-black font-medium">
              Invoice Date
            </label>
            <div className={errors.todayDate ? 'border border-red-500' : ''} >
              <CustomDatePicker
                selectedDate={invoiceDetails.todayDate}
                onChange={handleDateChange}
              />
            </div>
          </div>

          <div className="flex">
            <label className="w-1/3 text-baseSm text-primary-black font-medium">
              Amount
            </label>
            <input
              type="number"
              name="amount"
              placeholder="_ _ _ _ _ _ _"
              value={invoiceDetails.amount}
              onChange={handleInputChange}
              className={`w-2/3 py-1 bg-[#F3F3F3] text-end text-[#545454] font-normal ${
                errors.amount ? 'border border-red-500' : ''
              }`}
              readOnly={!isEdit}
            />
          </div>
          <div className="w-full flex border-t-2 border-line-color0 pt-3">
            <label className="w-1/3 text-base font-medium text-primary-black">
              Total
            </label>
            <input
              type="text"
              name="total"
              value={`INR ${invoiceDetails.amount}`}
              onChange={handleInputChange}
              className="w-2/3 py-1 bg-[#F3F3F3] text-end text-primary-black font-medium"
              readOnly={!isEdit}
            />
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="fixed bottom-0 bg-white border-t-[1px] shadow-top1 h-[8rem] w-full flex justify-around items-center gap-3 p-4">
        <Button
          label="Retake"
          btTextColor="text-primary"
          btBgColor="white"
          onClick={() => handleNavigate('/camera')}
        />
        <Button
          label="Save"
          onClick={handleSaveClick}
          btBgColor="bg-primary"
          btTextColor="text-white"
        />
      </div>
      {showSuccessModal && (
        <PopupModal
          time={2000}
          isOpen={showSuccessModal}
          message={errorMessage || 'Saved Successfully'}
          type={apiError ? 'Fail' : 'success'}
          onClose={handleSuccessModalClose}
        />
      )}
    </div>
  );
};

export default CapturedDetails;
