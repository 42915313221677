import React from 'react';
import Input from '../../components/input';
import Spinner from '../../components/Spinner';


const SfcFormTwo = ({ onChangeHandler, formValues, disableOneway, errors, loading }) => {

  return (
    <div className="max-w-2xl mx-auto">
      {loading && <Spinner text="Loading" transparent />}
      <div className="text-secondary-400 text-fieldset">Distance</div>
      <div className="w-1/1 h-[0.5px] bg-line-color mt-2"></div>
      <div className="flex gap-2">
        <Input
          label="One-way"
          name="oneWayDistance"
          value={formValues?.oneWayDistance}
          onChange={onChangeHandler}
          disabled={true}
          // disabled={disableOneway}
          // error={errors.oneWayDistance}
        />
        <Input label="Two-way" value={formValues?.twoWayDistance} disabled />
      </div>
      <div className="mt-5">
        <div className="text-secondary-400 text-fieldset">Fare & Allowance</div>
        <div className="w-1/1 h-[0.5px] bg-line-color mt-2"></div>
        <div className="grid grid-cols-2 gap-2">
          <Input
            type="text"
            label="One-way Fare"
            value={formValues?.oneWayFare}
            disabled
          />
          <Input
            type="text"
            label="Two-way Fare"
            value={formValues?.twoWayFare}
            disabled
          />
          <Input
            type="text"
            label="Allowance"
            value={formValues?.allowance}
            disabled
          />
          <Input type="text" label="Total" value={formValues?.total} disabled />
        </div>
      </div>
    </div>
  );
};

export default SfcFormTwo;
