import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../axiosInstance'


//get all expense type
export const fetchExpenseType = createAsyncThunk('camera/fetchExpenseType', async () => {
  try {
    const response = await axiosInstance.get('/ExpenseType');
    // console.log('response.data.getAllExpense', response.data.getAllExpense);
    return response.data.getAllExpense;
  } catch (error) {
    console.error('Error fetching expense type:', error);
    return null;
  }
})


//submit expense type
export const setExpenseSubmit = createAsyncThunk("camera/setExpenseSubmit", async (data,{rejectWithValue}) => {
  try {
    console.log('data', data);
    const uploadResponse = await axiosInstance.post(
      `/ExpenseUploadTemp`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    console.log('File upload response:', uploadResponse);
    return uploadResponse.data.expenseUploadTempList;
  } catch (error) {
    console.log('error', error);
    console.error('Error fetching expense type:', error.response.data);
    return rejectWithValue(error.response.data);
  }
})


const initialState = {
  expenseType:[],
  captureImage: '',
  isLoading: false,
  apiError: null,
  errorMessage: null,
  expenseSubmitResponse:[],
}

export const cameraSlice = createSlice({
  name: 'camera',
  initialState,
  reducers: {
    setCaptureImage: (state,{payload}) => {
      // console.log('payload', payload);
      state.captureImage = payload
    },
  },



  extraReducers: (builder) => {
    builder
    // get all expense type
      .addCase(fetchExpenseType.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchExpenseType.fulfilled, (state, { payload }) => {
        state.isLoading = false
        // console.log('action.payload', payload);
        state.expenseType = payload
        state.apiError = null
      })
      .addCase(fetchExpenseType.rejected, (state, { payload }) => {
        state.isLoading = false
        state.apiError = payload
        state.errorMessage = payload.message
      })

      // submit expense
      .addCase(setExpenseSubmit.pending, (state) => {
        console.log('state', state);
        state.isLoading = true
      })
      .addCase(setExpenseSubmit.fulfilled, (state, { payload }) => {
        state.isLoading = false
        console.log('action.payload', payload);
        state.expenseSubmitResponse = payload
        state.apiError = null
      })
      .addCase(setExpenseSubmit.rejected, (state, { payload }) => {
        state.isLoading = false
        state.apiError = true
        state.errorMessage = payload
      })

  }
})

export const {  setCaptureImage } = cameraSlice.actions


export const getAllExpenseType = (state) => state.camera.expenseType;
export const getExpenseSubmitResponse = (state) => state.camera
export const getCapturedImage = (state) => state.camera.captureImage
export default cameraSlice.reducer