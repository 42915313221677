import React from 'react';
import homeGrayIcon from '/assets/images/homeGrayIcon.png';
import barIcon from '/assets/images/barIcon.png';
import unReportedIcon from '/assets/images/unReportedIcon.png';
import expenseIcon from '/assets/images/expenseIcon.png';
import settingIcon from '/assets/images/settingIcon.png';
import logoutIcon from '/assets/images/logoutIcon.png';
// unreportedBillIcon
import unreportedBillIcon from '/assets/images/unreportedBillIcon.png';



// const Home = lazy(() => import('../pages/home'));

import {
    Home,
    Sfc,
    MyExpense,
    User,
    Setting,
    Logout,
    CameraOption,
    Notification,
    CapturedImage,
    CapturedDetails,
    UnReportedExpenses,
    CropImage,
    MyExpenseDetails,
    PageNotFound,
    Login


} from '../pages/route';


export const routersConfig = [
    // { path: '/', element: <Login />},
    // { path: '/login', element: <Login />},
    // { path: '/home', element: <Home />},
    { path: '/sfc', element: <Sfc />},
    { path: '/my-expense', element: <MyExpense />},
    { path: '/user', element: <User />},
    { path: '/setting', element: <Setting />},
    { path: '/logout', element: <Logout />},
    { path: '/camera', element: <CameraOption />},
    { path: '/notification', element: <Notification />},
    { path: '/capture-image', element: <CapturedImage />},
    { path: '/captured-image-details', element: <CapturedDetails />},
    { path: '/crop-image', element: <CropImage />},
    { path: '/un-reported-expenses', element: <UnReportedExpenses />},
    { path: '/my-expense-details', element: <MyExpenseDetails />},
    { path: '*', element: <PageNotFound />, }
];

//side bar
export const sideBarConfig = [
    { path: '/home', element: <Home />,icon:<img src={homeGrayIcon} alt="homeIcon" height={'20px'} width={'20px'} />,name:'Home' },
    { path: '/sfc', element: <Sfc />,icon:<img src={barIcon} alt="homeIcon" height={'20px'} width={'20px'} />,name:'Standard Fare Chart' },
    { path: '/my-expense', element: <UnReportedExpenses />,icon:<img src={expenseIcon} alt="expnseIcon" height={'20px'} width={'20px'} />,name:'My Expense' },
    { path: '/un-reported-expenses', element: <MyExpense />,icon:<img src={unreportedBillIcon} alt="unReportedIcon" height={'20px'} width={'20px'} />,name:'Unreported Bills' },
    { path: '/setting', element: <Setting />,icon:<img src={settingIcon} alt="settingIcon" height={'20px'} width={'20px'} />,name:'Setting' },
    { path: '/logout', element: <Logout />,icon:<img src={logoutIcon} alt="logoutIcon" height={'20px'} width={'20px'} />,name:'Logout' },
]


    // export  {routersConfig,sideBarConfig};
