import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axiosInstance';
import { errorResponseParser, successResponseParser } from '../../utils/handlers';

const baseURL = 'https://keaexpenseapi.kea-sfe.com/api/ExpenseIdCreation';
// const baseURL = "http://192.168.1.243:1105/api/ExpenseIdCreation";

const defaultParams = {
  employeeId: 9408,
  effectiveDate: '2024-09-25',
};
export const sfcOnewayDistanceCalculation = createAsyncThunk(
  'sfcOnewayDistanceCalculation',
  async (oneWayDistance,{ rejectWithValue, dispatch }) => {
    try {
      const res =  axios.get(`/SFCFareAndAllowanceForDistance`, {
        params: { ...defaultParams, oneWayDistance },
      });
      return successResponseParser(res);
    } catch (error) {
      return rejectWithValue(errorResponseParser(error, dispatch));
    }
  }
);

export const sfcOnewayDistance = createSlice({
  name: 'sfcOnewayDistance',
  initialState: {
    isLoading: false,
    data: null,
    apiError: '',
  },
  extraReducers: (builder) => {
    builder.addCase(sfcOnewayDistanceCalculation.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(sfcOnewayDistanceCalculation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(sfcOnewayDistanceCalculation.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.data = null;
    });
  },
});

export default sfcOnewayDistance.reducer;
