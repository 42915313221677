import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  userInfo } from '../../utils/Constant';
import axiosInstance from '../axiosInstance';
const { employeeId ,companyId} = userInfo;

// -----------------------------------DayWise Expense
export const fetchDayWiseExpense = createAsyncThunk(
  'myExpense/fetchDayWiseExpense',
  async (activityDate) => {
    try {
      const { expenseId } = JSON.parse(localStorage.getItem('SelectedExpenseData'));
      const response = await axiosInstance.get(
        `/expenseUpdateDayWise?employeeId=${employeeId}&expenseId=${expenseId}&activityDate=${activityDate}`
      );
      return response?.data;
    } catch (error) {
      return null;
    }
  }
);

// --------------------------------------- DayWise Total Expense
export const fetchDayWiseTotalExpense = createAsyncThunk(
  'myExpense/fetchDayWiseTotalExpense',
  async () => {
    try {
      const { expenseDetailId, expenseId, activityDate } = JSON.parse(
        localStorage.getItem('SelectedExpenseData')
      );
      console.log('expenseDetailId, expenseId, activityDate', expenseDetailId, expenseId, activityDate);
      if (expenseDetailId && expenseId && activityDate) {
        const response = await axiosInstance.get(
          `/expenseDayWiseTotal?employeeId=${employeeId}&expenseId=${expenseId}&activityDate=${activityDate}`
        );
        console.log('response.data', response.data.totalFare);
        return response.data.totalFare;
      }
    } catch (error) {
      return null;
    }
  }
);

// ----------------------------------------------------get All the attachment for daywise expense
export const fetchAttachmentDayWise = createAsyncThunk(
  'unReported/fetchAttachmentDayWise',
  async () => {
    const { expenseDetailId, expenseId, activityDate } = JSON.parse(localStorage.getItem('SelectedExpenseData'));
    // console.log('expenseDetailId, expenseId, activityDate', expenseDetailId, expenseId, activityDate);
    try {
      if (!expenseDetailId || !expenseId || !activityDate) {
        return null;
      } else {
        const response = await axiosInstance.get(
          `/expenseGetAllAttachmentForDaywise?employeeId=${employeeId}&expenseId=${expenseId}&expenseDetailId=${expenseDetailId}&activityDate=${activityDate}`
        );
        // console.log('response.data', response?.data?.expenseGetAllAttachmentForDaywise);
        return response?.data?.expenseGetAllAttachmentForDaywise;
      }
    } catch (error) {
      return null;
    }
  }
);

// ------------------------------Delete Attachment

export const deletedAttachmentDayWise = createAsyncThunk(
  'myExpense/deletedAttachmentDayWise',
  async (attachmentId) => {
    try {
      const SelectedExpenseData = JSON.parse(localStorage.getItem('SelectedExpenseData'));
      const { expenseId, expenseDetailId, activityDate } = SelectedExpenseData;
      const response = await axiosInstance.delete(
        `/expenseDeleteAttachment`,
        {
          params: {
            employeeId,
            expenseId,
            expenseDetailId,
            activityDate,
            expenseAttachmentId: attachmentId,
          },
        }
      );
      return response.data.expenseDeleteAttachmentList;
    } catch (error) {
      console.error('Error while deleting attachment:', error);
      return null;
    }
  }
);



// ----------------------------------------------------------My Expense Day Wise Save------------------------------
export  const expenseUpdateDateWiseSubmit = createAsyncThunk(
  'myExpense/expenseUpdateDateWiseSubmit',
  async (data) => {
    console.log('data12', data);
    try {
      const response = await axiosInstance.put(
        `/expenseUpdateDateWiseSubmit`,
        data
      );
      console.log('response.datawqw', response.data);
      return response.data;
    } catch (error) {
      console.error('Error while deleting attachment:', error);
      return null;
    }
  }
);



// ----------------------------------------------------------My Expense Day Wise------------------------------
const myExpenseDayWiseSlice = createSlice({
  name: 'myExpenseDayWise',
  initialState: {
    isLoading: false,
    dayWiseTotalExpense: null,
    dayWiseExpense: [],
    attachmentDayWise: [],
    dayWiseExpenseSubmit:[],
    errorMessage: '',
    apiError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      ///DayWise Expense Update
      .addCase(fetchDayWiseExpense.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDayWiseExpense.fulfilled,(state, { payload }) => {
          state.isLoading = false;
          console.log('action.payload', payload);
          state.dayWiseExpense = payload?.getMyExpenseList;
          state.apiError = false;
        }
      )
      .addCase(fetchDayWiseExpense.rejected, (state) => {
        state.isLoading = false;
        state.dayWiseExpense = null;
        state.apiError = 'Something is Wrong With API!';
      })
      .addCase(fetchDayWiseTotalExpense.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDayWiseTotalExpense.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        console.log('action.payload', payload);
        state.dayWiseTotalExpense = payload;
      })
      .addCase(fetchDayWiseTotalExpense.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action?.error?.message;
        state.apiError = true;
      })

      // Attachment Daywise
      .addCase(fetchAttachmentDayWise.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAttachmentDayWise.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        console.log('action.payload', payload);
        state.attachmentDayWise = payload;
      })
      .addCase(fetchAttachmentDayWise.rejected, (state, { payload }) => {
        state.isLoading = false;
        console.log('payload', payload);
        state.apiError = payload;
      })

      // Delete Attachment
      .addCase(deletedAttachmentDayWise.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletedAttachmentDayWise.fulfilled, (state, { payload }) => {
        console.log('payload', payload);
        state.isLoading = false;
        state.attachmentDayWise = payload;
        state.apiError = false;
        state.errorMessage = "";
      })
      .addCase(deletedAttachmentDayWise.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.apiError = payload;
        state.errorMessage = payload;
      })


      // DayWise Expense Submit
      .addCase(expenseUpdateDateWiseSubmit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(expenseUpdateDateWiseSubmit.fulfilled, (state, { payload }) => {
        console.log('payload', payload);
        state.isLoading = false;
        state.dayWiseExpenseSubmit = payload;
        state.apiError = false;
        state.errorMessage = "";
      })
      .addCase(expenseUpdateDateWiseSubmit.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.apiError = payload;
        state.errorMessage = payload;
      })
  },
});


export const getExpenseDayWiseAll = (state) => state.myExpenseDayWise;

//below line can be deleted
export const getDayWiseExpense = (state) => state.myExpenseDayWise.dayWiseExpense;
export const getAttachmentDaywise = (state) => state.myExpenseDayWise.attachmentDayWise;
export const getDayWiseTotalExpense = (state) => state.myExpenseDayWise.dayWiseTotalExpense;


export default myExpenseDayWiseSlice.reducer;
