import React, { useEffect, useState } from 'react';
import Dropdown from '../../components/dropdown/Dropdown';
import Textarea from '../../components/textarea/Textarea';
import Button from '../../components/button/Button';
import { priodSatate } from '../../utils/Constant';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  fetchActivityModifyRoute,
  fetchCityListModifyRoute,
  getExpenseActivityModifyRoute,
  getCityNameModifyRoute,
  getFromCityModifyRoute,
  fetchFromCityModifyRoute,
  setModifySubmit
} from '../../redux/slice/modifyRouteSlice';
import { useNavigate } from 'react-router-dom';
import { userInfo } from '../../utils/Constant';

const { employeeId, companyId } = userInfo;

import deleteIcon from '/assets/images/deleteIcon.png';

const ModifyRoutes = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cityName = useSelector(getCityNameModifyRoute);
  const activityName = useSelector(getExpenseActivityModifyRoute);
  const fromCityName = useSelector(getFromCityModifyRoute);
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [toCityNowFromCity, setToCityNowFromCity] = useState('');
  const [modifyRoutesData, setModifyRoutesData] = useState({});
  const [errors, setErrors] = useState({
    isPeriod: false,
    isActivity: false,
    isToCity: false,
  });
  const [data, setData] = useState({
    isStay: '',
    remarks: '',
    tblRoute: [],
  });

  let newFromCity = tableData.length === 0 ? fromCityName?.cityName : toCityNowFromCity;

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchActivityModifyRoute());
      dispatch(fetchCityListModifyRoute());
      dispatch(fetchFromCityModifyRoute());
    }
  }, [isOpen, dispatch]);

  const handleAdd = (event) => {
    event.preventDefault();
    const { period, activity, toCity } = modifyRoutesData;
    const SelectedExpenseData =
      JSON.parse(localStorage.getItem('SelectedExpenseData')) || {};
    const { expenseId, activityDate } = SelectedExpenseData;

    const newFromCity =
      tableData.length === 0 ? fromCityName?.cityName : toCityNowFromCity;
    const newToCity =
      cityName.find((item) => item.cityId === toCity)?.cityName || '';
    const newPeriod =
      priodSatate.find((item) => item.value === period)?.periodName || '';
    const newActivity =
      activityName.find((item) => item.activityTypeId === activity)
        ?.activityName || '';

    const errors = {
      isPeriod: !newPeriod ? 'Please select period' : false,
      isActivity: !newActivity ? 'Please select activity' : false,
      isToCity: !newToCity ? 'Please select to city' : false,
    };

    setErrors((prev) => ({ ...prev, ...errors }));

    if (newPeriod && newActivity && newToCity && newFromCity) {
      setTableData((prev) => [
        ...prev,
        {
          period: newPeriod,
          activity: newActivity,
          fromCity: newFromCity,
          toCity: newToCity,
        },
      ]);
    }
    setData((prev) => ({
      ...prev,
      employeeId,
      expenseId,
      companyId,
      period: period,
      activity: String(activity),
      activityDate: activityDate,
      tblRoute: [
        ...prev.tblRoute,
        {
          Slno: String(prev.tblRoute.length + 1),
          ActTypeId: String(prev.tblRoute.length + 1),
          Period: String(period),
          FromCity:
            tableData.length === 0
              ? String(fromCityName.cityId)
              : toCityNowFromCity,
          ToCity: String(toCity),
        },
      ],
    }));

    setToCityNowFromCity(newToCity);
    if (period && activity && toCity) {
      setModifyRoutesData((prev) => ({
        ...prev,
        period: '',
        activity: '',
        toCity: '',
        fromCity: '',
      }));
    }

    if (!showTable) {
      setShowTable(true);
    }
  };

  const handleDeleteRow = (index) => {
    setTableData((prev) => prev.filter((_, i) => i !== index));
    setShowTable(tableData.length > 1);
  };

  const handleModifyRouteSelector = (data) => {
    setModifyRoutesData((prev) => ({ ...prev, ...data }));
  };

  useEffect(() => {}, [modifyRoutesData]);

  const handlerChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    const errors = {
      isStay: !data.isStay ? 'Please select Stay or not' : false,
      remarks: !data.remarks ? 'Please select Remark' : false,
    };

    data.isStay = data.isStay == '' ? '' : data.isStay == '1' ? 1 : 0;
    setErrors((prev) => ({ ...prev, ...errors }));
    if (
      data.isStay === 0 ||
      (data.isStay === 1 && data.remarks && tableData.length > 0)
    ) {
      dispatch(setModifySubmit(data));
      setTimeout(() => {
        onClose();
      }, 2000);
    }
  };

  return (
    <div>
      {/* Drawer Overlay */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity ${
          isOpen
            ? 'opacity-100 overflow-scroll'
            : 'opacity-0 pointer-events-none'
        }`}
        onClick={onClose}
      ></div>

      {/* Drawer */}
      <div
        className={`fixed bottom-0 left-0 w-full shadow-lg transition-transform transform p-6 rounded-t-3xl h-[90%] bg-white ${
          isOpen ? 'translate-y-0 overflow-scroll' : 'translate-y-full'
        }`}
      >
        <div className="mb-8">
          <button
            className="absolute top-4 right-4  text-primary text-4xl"
            onClick={onClose}
          >
            &times;
          </button>
        </div>

        <div className="space-y-6">
          {/* Period & Activity */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-baseMd text-[#514D4C] pb-1">
                Period
              </label>
              <Dropdown
                label="Select"
                name="period"
                value={modifyRoutesData.period}
                options={priodSatate}
                attributeValue="value"
                attributeName="periodName"
                onChange={handleModifyRouteSelector}
              />
              {errors.isPeriod && (
                <div className="text-red-500">{errors.isPeriod}</div>
              )}
            </div>

            <div>
              <label className="block text-baseMd text-[#514D4C] pb-1">
                Activity
              </label>
              <Dropdown
                label="Select"
                name="activity"
                attributeValue="activityTypeId"
                value={modifyRoutesData.activity}
                options={activityName}
                attributeName="activityName"
                onChange={handleModifyRouteSelector}
              />
              {errors.isActivity && (
                <div className="text-red-500">{errors.isActivity}</div>
              )}
            </div>
          </div>

          {/* From City & To City */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-baseMd text-[#514D4C] pb-1">
                From City
              </label>
              <Dropdown
                isDisable={true} // Added isDisable prop
                disabledValue={newFromCity} // Optional value when disabled
                name="fromCity"
                value={fromCityName?.cityName}
                attributeName="cityName"
                options={fromCityName}
                attributeValue="cityId"
                onChange={handleModifyRouteSelector}
              />
            </div>

            <div>
              <label className="block text-baseMd text-[#514D4C] pb-1">
                To City
              </label>
              <Dropdown
                label="Select"
                name="toCity"
                attributeName="cityName"
                value={modifyRoutesData.toCity}
                options={cityName}
                attributeValue="cityId"
                onChange={handleModifyRouteSelector}
              />
              {errors.isToCity && (
                <div className="text-red-500">{errors.isToCity}</div>
              )}
            </div>
          </div>

          {/* Add Button */}
          <div className="flex justify-center w-full">
            <button
              className="px-4 py-1 text-white border-[1px] border-primary rounded-3xl w-full"
              onClick={handleAdd}
            >
             <p className='text-center text-primary text-base font-semibold'>Add</p>
            </button>
          </div>
        </div>

        {/* Table */}
        {showTable && tableData?.length > 0 && (
          <div className="mt-4 overflow-x-auto">
            <div className="max-h-40 overflow-y-auto">
              {/* Fixed height with vertical scroll */}
              <table className="table-auto w-full text-center border border-gray-300">
                <thead className="sticky top-0 bg-gray-200">
                  {/* Sticky header */}
                  <tr>
                    {[
                      'Sl No',
                      'Period',
                      'Activity',
                      'From City',
                      'To City',
                      'Action',
                    ].map((heading) => (
                      <th
                        key={heading}
                        className="px-4 py-2 border bg-gray-200"
                      >
                        <p className="tex-base font-medium text-[#514D4C]">{heading}</p>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData &&
                    tableData?.length > 0 &&
                    tableData?.map((item, index) => (
                      <tr key={index}>
                        <td className="px-4 py-2 border text-baseMd">
                          {index + 1}
                        </td>
                        <td className="px-4 py-2 border text-baseMd">
                          {item.period}
                        </td>
                        <td className="px-4 py-2 border text-baseMd">
                          {item.activity}
                        </td>
                        <td className="px-4 py-2 border text-baseMd">
                          {item.fromCity}
                        </td>
                        <td className="px-4 py-2 border text-baseMd">
                          {item.toCity}
                        </td>
                        <td className="px-4 py-2 border">
                          <button onClick={() => handleDeleteRow(index)}>
                            <img
                              src={deleteIcon}
                              alt="deleteIcon"
                              width={'20px'}
                              height={'20px'}
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* Stayed Over Night */}
        <div className="mt-4">
          <p className="text-base text-[#514D4C] pb-1">Stayed Over Night</p>
          <div className="flex space-x-4 mt-2">
            {['No', 'Yes'].map((label, idx) => (
              <div key={label} className="flex items-center space-x-2">
                <input
                  type="radio"
                  id={label}
                  name="isStay"
                  value={idx}
                  checked={data.isStay == String(idx)}
                  onChange={(e) => handlerChange(e)}
                  className="form-radio text-blue-500"
                />
                <label htmlFor={label} className="text-gray-700">
                  {label}
                </label>
              </div>
            ))}
          </div>
          {errors.isStay && <div className="text-red-500">{errors.isStay}</div>}
        </div>

        {/* Remarks */}
        <div className={`mt-4 ${tableData?.length > 0 ? 'mb-[10rem]' : ''}`}>
          <label className="text-base text-[#514D4C] pb-1">Remarks</label>
          {errors.remarks && (
            <div className="text-red-500">{errors.remarks}</div>
          )}
          <div className='mt-1'>
          <Textarea
            name="remarks"
            value={data.remarks}
            onChange={handlerChange}
            textAreaClassName="h-32 w-full border border-gray-300 rounded-lg p-2"
            placeholder="Enter your Remarks here..."
          />
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 left-0 bg-white border-t-[1px] shadow-top1 h-[7rem] w-full flex justify-around items-center gap-3 p-4">
        <Button
          label="Close"
          btTextColor="text-primary"
          btBgColor="bg-white"
          onClick={onClose}
        />
        <Button
          label="Submit"
          btTextColor="text-white"
          btBgColor="bg-primary"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default ModifyRoutes;
