import React from 'react';
const Setting = () => {
  return (
    <div className="flex items-center justify-center mt-12 flex-col">
      {/* Empty page Setting */}
    </div>
  );
};

export default Setting;
