import React from 'react';

const Textarea = ({
  name,
  value,
  onChange,
  placeholder,
  textAreaClassName,
}) => {
  return (
    <textarea
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={`${textAreaClassName}
      `}
    />
  );
};

export default Textarea;
