import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { userInfo } from '../../utils/Constant';
import axiosInstance from '../axiosInstance';

const { employeeId, companyId, month, year } = userInfo;




// my expense data
export const fetchMyExpenseData = createAsyncThunk(
  'myExpense/fetchMyExpenseData',
  async (_,{getState}) => {
    const { userInfo } = getState().auth;
    const { employeeId, companyId,expenseMonth, expenseYear } = userInfo;
    try {
      let response = await axiosInstance.get(
        `/getMyExpense?employeeId=${employeeId}&month=${expenseMonth}&year=${expenseYear}&companyId=${companyId}`
      );
      // console.log('response', response.data.getMyExpenseList);
      return response.data.getMyExpenseList;
    } catch (error) {
      return error.message;
    }
  }
);

//Fetch City Name for Filter in my expense
export const fetchExpenseCityFilter = createAsyncThunk(
  'myExpense/fetchExpenseCityFilter',
  async (_,{getState}) => {
    try {
      const { userInfo } = getState().auth;
      const { employeeId } = userInfo;
      const response = await axiosInstance.get(
        `/expenseCityFilter?employeeId=${employeeId}`
      );
      return response.data.expenseCityFilterList;
    } catch (error) {
      console.log('Error while  fetchExpenseCityFilter', error);
      return null;
    }
  }
);


//Final my expense Submit
export const setExpenseFinalSubmit = createAsyncThunk(
  'myExpense/expenseFinalSubmit',
  async () => {
    try {
      const response = await axiosInstance.post(`/expenseFinalSubmit`, {
        companyId,
        employeeId,
      });
      // console.log('response.data', response.data);
      return response.data;
    } catch (error) {
      console.log('Error while setExpenseFinalSubmit', error);
      return null;
    }
  }
);

// My Expense Slice
const myExpenseSlice = createSlice({
  name: 'myExpense',
  initialState: {
    isLoading: false,
    // selectedExpense: [],
    expenseCityFilter: [],
    myExpenseSubmit: null,
    myExpenseData: [],

    apiError: false,
    errorMessage: '',
  },
  reducers: {
    // setSelectedExpense: (state, { payload }) => {
    //   console.log('setSelectedExpense', payload);
    //   state.selectedExpense = payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyExpenseData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMyExpenseData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.myExpenseData = payload;
      })
      .addCase(fetchMyExpenseData.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.error.message;
        state.apiError = true;
      })


      //city  Name for filter
      .addCase(fetchExpenseCityFilter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchExpenseCityFilter.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.expenseCityFilter = payload;
        state.apiError = false;
      })
      .addCase(fetchExpenseCityFilter.rejected, (state) => {
        state.isLoading = false;
        state.expenseCityFilter = null;
        state.apiError = 'Something is Wrong With API!';
      })

      //Final submit expense
      .addCase(setExpenseFinalSubmit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setExpenseFinalSubmit.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        console.log('payload', payload.fileRecord);
        state.myExpenseSubmit = payload?.fileRecord;
        state.apiError = false;
      })
      .addCase(setExpenseFinalSubmit.rejected, (state) => {
        state.isLoading = false;
        state.myExpenseSubmit = null;
        state.apiError = 'Something is Wrong With API!';
      });
  },
});

//action and reducer
// export const { setSelectedExpense } = myExpenseSlice.actions;

export const getMyExpenseData = (state) => state.myExpense.myExpenseData;
export const getExpenseCityFilter = (state) =>state.myExpense.expenseCityFilter;
export const getFinalSubmitExpense = (state) =>state.myExpense.myExpenseSubmit;
export const getMyExpensedata = (state) =>state.myExpense

//get selected expense
// export const getSelectedExpense = (state) => state.myExpense.selectedExpense;

export default myExpenseSlice.reducer;
