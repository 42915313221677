import { createSlice } from "@reduxjs/toolkit";

const networkAlertSlice = createSlice({
    name: "alert",
    initialState: {
        message: "",
        showAlert: false 
    },
    reducers: {
        updateApiError: (state, action) => {
            state.message = action.payload
            state.showAlert = true
        },
        removeApiError: (state) => {
            state.message = "",
            state.showAlert = false
        }
    }
})

export const { updateApiError, removeApiError } = networkAlertSlice.actions;
export default  networkAlertSlice.reducer;