import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { baseURL, userInfo } from '../../utils/Constant';
import axios from 'axios';
import axiosInstance from '../axiosInstance';

const { employeeId, companyId } = userInfo;

//fetch expense activity
export const fetchActivityModifyRoute = createAsyncThunk(
  'modifyRoute/fetchActivityModifyRoute',
  async () => {
    try {
      const response = await axiosInstance.get(
        `/getExpenseActivity?employeeId=${employeeId}&companyId=${companyId}`
      );
      console.log('Response: APIIIIIIIIII', response.data.getExpenseActivityList);
      return response.data.getExpenseActivityList;
    } catch (error) {
      console.log('error', error.message);
      return null;
    }
  }
);

// get city name
export const fetchCityListModifyRoute = createAsyncThunk(
  'modifyRoute/fetchCityListModifyRoute',
  async () => {
    try {
      const response = await axiosInstance.get(
        `/getExpenseCity?employeeId=${employeeId}`
      );
      console.log('Response: APIIIIIIIIII', response.data);
      return response.data.getExpenseCityList;
    } catch (error) {
      console.log('error', error.message);
      return null;
    }
  }
);

///fetch from city modify
export const fetchFromCityModifyRoute = createAsyncThunk(
  'modifyRoute/fetchFromCityModifyRoute',
  async () => {
    const { expenseId, activityDate } = JSON.parse(localStorage.getItem('SelectedExpenseData'));
    console.log('expenseId, activityDate', expenseId, activityDate);
    try {
      const response = await axiosInstance.get(
        `/expenseModifyFromCity?employeeId=${employeeId}&expenseId=${expenseId}&activityDate=${activityDate}`
      );
      console.log('Response: APIIIIIIIIII', response.data);
      return response.data;
    } catch (error) {
      console.log('error', error.message);
      return null;
    }
  }
);
// --------------------------------------------------
// modify Submit
export const setModifySubmit = createAsyncThunk(
  'modifyRoute/setModifySubmit',
  async (data) => {
    console.log('setModifySubmit//@1', data);
    try {
      const response = await axiosInstance.post(`/expenseModifySubmit`, data);
      console.log('API Response:@1', response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      return null;
    }
  }
);



//slice
const modifyRouteSlice = createSlice({
  name: 'modifyRoute',
  initialState: {
    expenseActivity: [],
    cityList: [],
    fromCity: null,
    isLoading: false,
    error: null,
    errorMessage: null,
    respnseModifyRoute: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivityModifyRoute.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchActivityModifyRoute.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        console.log('action.payload', payload);
        state.expenseActivity = payload;
      })
      .addCase(fetchActivityModifyRoute.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.error.message;
        state.error = true;
      })

      // get city name
      .addCase(fetchCityListModifyRoute.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCityListModifyRoute.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        console.log('action.payload', payload);
        state.cityList = payload;
      })
      .addCase(fetchCityListModifyRoute.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.error.message;
        state.error = true;
      })

      //fetch from city modify
      .addCase(fetchFromCityModifyRoute.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFromCityModifyRoute.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        console.log('action.payload', payload);
        state.fromCity = payload;
      })
      .addCase(fetchFromCityModifyRoute.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.error.message;
        state.error = true;
      })

      // modify Submit
      .addCase(setModifySubmit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setModifySubmit.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        console.log('action.payload@1', payload);
        state.respnseModifyRoute = payload;
      })
      .addCase(setModifySubmit.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.error.message;
        state.error = true;
      });

  },
});

// action
export const getCityNameModifyRoute = (state) => state.modifyRoute.cityList;
export const getExpenseActivityModifyRoute = (state) =>state.modifyRoute.expenseActivity;
export const getFromCityModifyRoute = (state) => state.modifyRoute.fromCity;

export default modifyRouteSlice.reducer;
