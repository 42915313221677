import React, { useState, useRef, useEffect } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCapturedImage,
  setCaptureImage,
} from '../../redux/slice/cameraSlice';
import { Link } from 'react-router-dom';
import Button from '../../components/button/Button';
import { useNavigate } from 'react-router-dom';
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop({ unit: '%', width: 90 }, aspect, mediaWidth, mediaHeight),
    mediaWidth,
    mediaHeight
  );
}

function canvasPreview(image, canvas, crop, scale = 1) {
  const ctx = canvas.getContext('2d');
  if (!ctx) throw new Error('No 2d context');

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  ctx.save();
  ctx.translate(-cropX, -cropY);
  ctx.translate(image.naturalWidth / 2, image.naturalHeight / 2);
  ctx.scale(scale, scale);
  ctx.translate(-image.naturalWidth / 2, -image.naturalHeight / 2);
  ctx.drawImage(image, 0, 0);
  ctx.restore();
}

function useDebounceEffect(fn, waitTime, deps) {
  useEffect(() => {
    const timeout = setTimeout(fn, waitTime);
    return () => clearTimeout(timeout);
  }, deps);
}

export default function CropImage() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const newImage = useSelector(getCapturedImage);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState('');
  const [completedCrop, setCompletedCrop] = useState('');
  const [scale, setScale] = useState(1);
  const [aspect, setAspect] = useState(1, 32);

  useEffect(() => {
    setTimeout(() => setAspect(undefined), 1000);
  }, []);

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect));
  }
  const handlerNavigate = (navigateTo) =>navigate(navigateTo);


  async function handlerCropImage() {
    try {
      const image = imgRef.current;
      const previewCanvas = previewCanvasRef.current;
      if (!image || !previewCanvas || !completedCrop) {
        throw new Error('Crop canvas does not exist');
      }

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const offscreen = new OffscreenCanvas(
        completedCrop.width * scaleX,
        completedCrop.height * scaleY
      );
      const ctx = offscreen.getContext('2d');
      if (!ctx) throw new Error('No 2d context');

      ctx.drawImage(
        previewCanvas,
        0,
        0,
        previewCanvas.width,
        previewCanvas.height,
        0,
        0,
        offscreen.width,
        offscreen.height
      );

      const blob = await offscreen.convertToBlob({ type: 'image/png' });

      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(setCaptureImage(reader.result));
      };
      reader.readAsDataURL(blob);
    navigate('/capture-image');
    } catch (error) {
      console.error('Error in handlerCropImage:', error);
    }
  }

  useDebounceEffect(
    () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale
        );
      }
    },
    100,
    [completedCrop, scale]
  );

  return (
    <div className="flex flex-col  bg-gray-100">
      {newImage && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
        >
          <img
            ref={imgRef}
            alt="Crop image"
            src={newImage}
            onLoad={onImageLoad}
            className="rounded border-2 border-gray-300 shadow-lg"
            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
          />
        </ReactCrop>
      )}

      {/* {newImage && (
        <div className="mt-4 flex justify-center">
          <canvas ref={previewCanvasRef} style={{ display: 'none' }} />
          <Link to="/capture-image">
            <button
              onClick={handlerCropImage}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-lg"
            >
              Crop Image
            </button>
          </Link>
        </div>
      )} */}
      {/* Footer Buttons */}
      {newImage && (
        <div className="fixed bottom-0 bg-white border-t-1 shadow-top1 h-[8rem] w-full flex justify-around items-center gap-3 p-4">
          <canvas ref={previewCanvasRef} style={{ display: 'none' }} />

          <Button
            label="Cancel"
            btTextColor="text-primary"
            btBgColor="white"
            onClick={() => handlerNavigate('/capture-image')}
          />
          <Button
            label="Crop Image"
            btBgColor="bg-primary"
            btTextColor="text-white"
            onClick={handlerCropImage}
          />
        </div>
      )}
    </div>
  );
}
