import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes,faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { sideBarConfig } from '../../router/routersConfig'; // Update with the correct path

import  menu_listIcon from '/assets/images/menu_listIcon.png';
import defaultUserIcon from '/assets/images/defaultUserIcon.png';


const SideBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const drawerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  let userData = JSON.parse(localStorage.getItem('userData'));


  return (
    <div className="relative">
      <button
        className="text-gray-500 dark:text-gray-400 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm pt-4 pb-4"
        type="button"
        onClick={() => setIsOpen(true)}
      >
        <FontAwesomeIcon icon={faBars}  size='xl' />
       {/* <img src={menu_listIcon} alt="menu_listIcon" className="w-10 h-10"  /> */}
      </button>

      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 z-30 bg-[#F3F3F3] opacity-75"
          onClick={() => setIsOpen(false)}
        ></div>
      )}

      {/* Drawer component */}
      <div
        ref={drawerRef}
        className={`fixed top-0 left-0 z-40 h-full w-72 bg-white dark:bg-gray-800 transform transition-transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
        tabIndex="-1"
      >
        {/* <button
          type="button"
          onClick={() => setIsOpen(false)}
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <FontAwesomeIcon icon={faTimes} />
          <span className="sr-only">Close menu</span>
        </button> */}

        <div className="flex flex-col h-full bg-white" style={{ fontSize: '16px', color: '#514D4C' }}>
          {/* Random User Info */}

          {/* make below Link uncomment in future Development */}
          {/* <Link to="/user" className="block p-4" onClick={() => setIsOpen(false)}> */}
            <div className="flex items-center p-4">
           {/* <FontAwesomeIcon icon={faUser} className="w-10 h-10 rounded-full" /> */}


              <img
                src={defaultUserIcon}
                alt="Random User"
                className="w-12 h-12 rounded-full"
              />
              <div className="ml-3">
              <p className="text-sm text-gray-500 dark:text-gray-400">{userData?.employeeName || 'Uknown'}</p>
              <p className="text-sm text-gray-500 dark:text-gray-400">{userData?.companyId || 'Company ID'}</p>
              <p className="text-sm text-gray-500 dark:text-gray-400">{userData?.companyName?.slice(0, 16) || 'Company Name'}</p>

                {/* <p className="text-lg font-bold text-gray-500 dark:text-gray-500">{userData?.employeeName || 'Uknown'}</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">{userData?.companyId || 'Company ID'}</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">{userData?.companyName?.slice(0, 16) || 'Company Name'}</p> */}
              </div>
            </div>
          {/* </Link> */}

          <div className="flex-1 overflow-y-auto">
            <ul className="space-y-2 font-medium p-4">
              {sideBarConfig.filter(item => item.path !== '/setting' && item.path !== '/logout').map(({ path, icon, element, name = '' }, index) => (
                <li key={index}>

                  <Link
                    to={path === '/user' ? '' : path}
                    className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white ${
                      isOpen ? 'hover:bg-[#FFA8F333]' : 'hover:bg-gray-100 dark:hover:bg-gray-700'
                    } group`}
                    onClick={() => setIsOpen(false)} // Optionally close the drawer on link click
                    style={{ fontSize: '16px', color: '#514D4C' }}
                  >
                    {icon}
                    <span className="ms-3">
                      {name}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex justify-between p-4">
            {sideBarConfig.filter(item => item.path === '/setting' || item.path === '/logout').map(({ path, icon, element }, index) => (
              // console.log('element404',path),
              <Link
                key={index}
                //remoed below condition in future developement
                to={path === '/logout' ?'/logout':'' }
                className={`p-2 text-gray-900 rounded-lg dark:text-white ${
                  isOpen ? 'hover:bg-[#FFA8F333]' : 'hover:bg-gray-100 dark:hover:bg-gray-700'
                }`}
                onClick={() => setIsOpen(false)} // Optionally close the drawer on link click
                style={{ fontSize: '16px', color: '#514D4C' }}
              >
                {icon}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
