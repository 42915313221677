import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../axiosInstance'

//fetch Home Page Expense
export const fetchHomeExpense = createAsyncThunk(
    'myExpense/fetchHomeExpense',
    async (_,{ getState }) => {
      const { userInfo } = getState().auth;
      const { employeeId, companyId } = userInfo;
      try {
        const response = await axiosInstance.get(
          `/ExpenseMainDashboard?EmployeeId=${employeeId}&CompanyId=${companyId}`
        );
        return response.data.expenseMain;
      // }
      } catch (error) {
        return error.message;
      }
    }
  );

  //create slice for my expense
  export const homeExpenseSlice = createSlice({
    name: 'homeExpense',
    initialState: {
      isLoading: false,
      homeExpense: [],
      apiError: false,
      errorMessage: '',
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchHomeExpense.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(fetchHomeExpense.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.homeExpense = payload;
        })
        .addCase(fetchHomeExpense.rejected, (state, action) => {
          state.isLoading = false;
          state.errorMessage = action.error.message;
          state.apiError = true;
        });
    },
  });

export const getHomeExpense = (state) => state.homeExpense;
export const homeExpenseReducer = homeExpenseSlice.reducer;
