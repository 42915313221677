import React from 'react';
const User = () => {
  return (
    <div className="flex items-center justify-center mt-12 flex-col">
        {/* Empty page User Profil  */}
    </div>
  );
};

export default User;
