import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axiosInstance';
import { errorResponseParser, successResponseParser } from '../../utils/handlers';



export const postSfcSaveTravelExpence = createAsyncThunk(
  'postSfcSaveTravelExpence',
  async (data, { rejectWithValue , dispatch}) => {
    try {
      const res = await axios.post(`/SFCAdd`, data);
      return successResponseParser(res);
    } catch (error) {
     return  rejectWithValue(errorResponseParser(error,dispatch))
    }
  }
);

export const updateSfcTravelExpense = createAsyncThunk(
  'updateSfcTravelExpense',
  async (editData, { rejectWithValue, dispatch }) => {
    try {
      const selectedTerritoryId = editData.fromTerritoryId;
      const res = await axios.put(`/SFCEditSave`, {
        ...editData,
        selectedTerritoryId,
      });
      return successResponseParser(res);
    } catch (error) {
     return  rejectWithValue(errorResponseParser(error,dispatch))
    }
  }
);

export const sfcSaveTravelExpence = createSlice({
  name: 'sfcSaveTravelExpence',
  initialState: {
    isLoading: false,
    data: null,
    apiError: '',
    isSuccess: false,
  },
  reducers: {
    clearState: (state) => {
      (state.apiError = ''), (state.data = null), (state.isLoading = false);
    },
    updateSaveSuccess: (state, action) => {
      state.isSuccess = action.payload;
    },
    clearApiStatus: (state) => {
      state.apiError = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postSfcSaveTravelExpence.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });

    builder.addCase(postSfcSaveTravelExpence.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = true;
    });
    builder.addCase(postSfcSaveTravelExpence.rejected, (state, action) => {
      state.apiError =
        action?.payload?.statusCode === 409
          ? action.payload?.sfadd?.result
          : "Couldn't connect with server at this moment, please try again later..";
      state.isLoading = false;
      state.data = null;
      state.isSuccess = false;
    });

    builder.addCase(updateSfcTravelExpense.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });

    builder.addCase(updateSfcTravelExpense.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = true;
    });
    builder.addCase(updateSfcTravelExpense.rejected, (state, action) => {
        state.apiError =
        action?.payload?.statusCode === 409
          ? action.payload?.sfEditSave?.result
          : "Couldn't connect with server at this moment, please try again later..";
      state.isLoading = false;
      state.data = null;
      state.isSuccess = false;
    });
  },
});
export const { clearState, updateSaveSuccess, clearApiStatus } =
  sfcSaveTravelExpence.actions;
export default sfcSaveTravelExpence.reducer;
