import { updateApiError } from '../redux/slice/networkAlertSlice';

export const successResponseParser = (response) => {
  return response.data;
};
export const errorResponseParser = (error, dispatch) => {
  if (error.response.status !== 409) {
    dispatch(
      updateApiError(
        error.response.status >= 500
          ? "Server Couldn't respond at this moment, please try again.."
          : error.response.status === 408
          ? 'network: taking too long to fetch data'
          : 'something went wrong, this maybe network issue'
      )
    );
  }
  return error?.response?.data;
};
