import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../components/button/Button';
// images import
import cropIcon from '/assets/images/cropIcon.png';
import editIcon from '/assets/images/editIcon.png';
import saveIcon from '/assets/images/saveIcon.png';
import leftArrowIcon from '/assets/images/leftArrowIcon.png';
import editSaveIcon from '/assets/images/editSaveIcon.png';
import { getCapturedImage } from '../../redux/slice/cameraSlice';
import { baseURL } from '../../utils/Constant';

const CapturedImage = () => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [invoiceName, setInvoiceName] = useState('');
  const imageSrc = useSelector(getCapturedImage);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setInvoiceName(e.target.value);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
  };

  const generateInvoiceNo = () => {
    setInvoiceName(
      Array.from({ length: 8 }, () => Math.floor(Math.random() * 10)).join('')
    );
  };

  useEffect(() => {
    generateInvoiceNo();
  }, []);

  const handlerNavigate = (navigateTo) => navigate(navigateTo);

  return (
    <div className="flex flex-col relative">
      <div className="bg-white flex items-center justify-between border-b border-line-color h-16 p-4">
        <button
          className="text-gray-500"
          onClick={() => handlerNavigate('/camera')}
        >
          <img
            src={leftArrowIcon}
            alt="leftArrowIcon"
            height={'10px'}
            width={'10px'}
          />
        </button>
        <div className="text-lg font-semibold flex-grow text-center">
          <div className="flex justify-center items-center gap-4">
            {isEditing ? (
              <input
                size={10}
                type="text"
                value={invoiceName}
                onChange={handleInputChange}
                className="w-32 border-t-0 border-l-0 border-r-0 border-b border-dashed border-gray-500 p-1 text-gray-400 font-normal"
              />
            ) : (
              <span className={`${isEditing ? 'text-gray-400 text-medium' : 'text-primary font-semibold'}`}>{invoiceName.length > 10 ? invoiceName.slice(0, 10) + '...' : invoiceName}</span>
            )}
            <button
              className="text-gray-500"
              onClick={isEditing ? handleSaveClick : handleEditClick}
            >
              <img
                src={isEditing ? editSaveIcon : editIcon}
                alt={isEditing ? 'Save' : 'Edit'}
                height="20px"
                width="20px"
              />
            </button>
          </div>
        </div>
        <div className="flex items-center space-x-2 gap-4">
          <button
            className="text-gray-500"
            onClick={() => handlerNavigate('/crop-image')}
          >
            <img src={cropIcon} alt="Crop" height={'20px'} width={'20px'} />
          </button>
        </div>
      </div>

      <div
        className="flex items-center justify-center p-4"
        style={{ height: '70vh', width: '100vw', marginTop: '2rem' }}
      >
        <img
          src={imageSrc}
          alt="Captured image"
          className="w-full h-full bg-slate-400 rounded-lg"
        />
      </div>

      {/* Footer Buttons */}
      <div className="fixed bottom-0 bg-white border-t-[1px] shadow-top1 h-[8rem]  w-full flex justify-around items-center gap-3 p-4">
        <Button
          label="Retake"
          btTextColor="text-primary"
          btBgColor="white"
          onClick={() => handlerNavigate('/camera')}
        />
        <Button
          label="Next"
          onClick={() => handlerNavigate('/captured-image-details')}
          btBgColor="bg-primary"
          btTextColor="text-white"
        />
      </div>
    </div>
  );
};

export default CapturedImage;
