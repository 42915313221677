import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { routersConfig } from './router/routersConfig';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import SplashScreen from './components/splashScreen/SplashScreen';

const AppComponent = () => {
  const navigate = useNavigate();

  const checkTokenAndNavigate = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
    }
  };

  useEffect(() => {
    checkTokenAndNavigate();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<SplashScreen />} />
      <Route path="/login" element={<Login />} />
      <Route path="/home" element={<Home />} />
      {routersConfig.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={route.element}
        />
      ))}
    </Routes>
  );
};

const App = () => <AppComponent />;

export default App;
