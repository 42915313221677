import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


//images
import galleryIcon from '/assets/images/galleryIcon.png';
import fileIcon from '/assets/images/fileIcon.png';
import scanlineIcon from '/assets/images/scanlineIcon.png';
import unreportedBillIcon from '/assets/images/unreportedBillIcon.png';

const FileAttachedModal = ({ isOpen, onClose, showModal }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedImages(files);
  };

  const handleNavigate = (path) => {
    navigate(path, { state: true }); // Pass true as state
    onClose();
  };


  const dividerClass = 'divider-y h-[1px] w-full bg-line-color';

  return (
    <>
      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="bg-[#F5F5F5] h-40 w-60 border-0 rounded-xl shadow-lg relative flex flex-col outline-none focus:outline-none  text-center pt-2">
                {/*header*/}
                <div className="flex ml-3 justify-between p-2 border-b border-solid rounded-t">
                  <button
                    onClick={() => handleNavigate('/un-reported-expenses')}
                    className="flex gap-3 items-center"
                  >
                    <img src={unreportedBillIcon} alt="unreportedBillIcon"  height={'12px'} width={'12px'}/>
                    <p className="text-baseSm font-medium text-[#514D4C]">Unreported Expenses</p>
                  </button>
                </div>
                <div className={dividerClass}></div>

                <div className="flex ml-3 justify-between p-2 border-b border-solid rounded-t">
                  <button
                    onClick={() => handleNavigate('/camera')}
                    className="flex gap-3 items-center"
                  >
                    <img src={scanlineIcon} alt="scanlineIcon" height={'12px'} width={'12px'}/>
                    <p className="text-baseSm font-medium text-[#514D4C]">Invoice Scan</p>{' '}
                  </button>
                </div>
                <div className={dividerClass}></div>
                <div className="flex ml-3 justify-between p-2 border-b border-solid rounded-t">
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    accept="image/*"
                    multiple
                    onChange={handleFileChange}
                  />
                  <button
                    onClick={() => document.getElementById('fileInput').click()}
                    className="flex gap-3 items-center"
                  >
                    <img src={galleryIcon} alt="fileIcon" height={'12px'} width={'12px'}/>
                    <p className="text-baseSm font-medium text-[#514D4C]">Photo</p>
                  </button>
                </div>
                <div className={dividerClass}></div>

                <div className="flex ml-3 justify-between p-2 border-b border-solid rounded-t">
                  <input
                    type="file"
                    id="fileInput1"
                    accept=".pdf"
                    style={{ display: 'none' }}
                    multiple
                    onChange={handleFileChange}
                  />
                  <button
                    onClick={() =>
                      document.getElementById('fileInput1').click()
                    }
                    className="flex gap-3 items-center"
                  >
                    <img src={fileIcon} alt="fileIcon" height={'12px'} width={'12px'}/>
                    <p className="text-baseSm font-medium text-[#514D4C]">Gallery</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 "></div>
        </>
      )}
    </>
  );
};

export default FileAttachedModal;
