import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../axiosInstance';
import { errorResponseParser, successResponseParser } from '../../utils/handlers';


export const postSfcSubmitTravelExpence = createAsyncThunk(
  'postSfcSubmitTravelExpence',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`/SFCSubmit`, data);
      return successResponseParser(res);
    } catch (error) {
      return rejectWithValue(errorResponseParser(error, dispatch));
    }
  }
);

export const sfcSubmitTravelExpence = createSlice({
  name: 'sfcSubmit',
  initialState: {
    isLoading: false,
    data: null,
    apiError: '',
    isSubmit: false
  },
  reducers: {
    updateSubmitSuccess: (state, action) => {
      state.isSubmit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postSfcSubmitTravelExpence.pending, (state) => {
      state.isLoading = true;
      state.isSubmit = false;
    });

    builder.addCase(postSfcSubmitTravelExpence.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSubmit = true
    });
    builder.addCase(postSfcSubmitTravelExpence.rejected, (state) => {
      state.apiError = 'Something went wrong';
      state.isLoading = false;
      state.data = null;
      state.isSubmit = false;

    });
  },
});

export const { updateSubmitSuccess } = sfcSubmitTravelExpence.actions;
export default sfcSubmitTravelExpence.reducer;
