import React, { useRef, useState } from 'react';
import CalenderIcon from '../../assets/icons/input-calender.png';
import './style.css';


//varient values should be animated , plane , vertical
const Datepicker = ({
  label,
  name,
  value,
  varient = 'animated',
  disabled,
  error,
  className,
  labelClassName = '',
  ...restProps
}) => {
  const [isFocused, setIsFocused] = useState(false);
  label = label || "Enter " + name
const datepicker = useRef(null)
  return (
    <div
      className={`custom-input w-[180px] mt-4 ${varient === "vertical" ? "flex flex-col gap-2 mt-0" : "relative"}  ${ error ? "form-error" : null}`}
      onClick={() => disabled || setIsFocused(true)}
    >
      {varient !== 'plane' ? (
        <label
          htmlFor={name}
          className={`datepicker-label font-medium whitespace-nowrap ${
            isFocused || value !== 'undefined' && value !== ''
              ? ''
              : 'text-secondary-200'
          } ${varient !== 'plane' ? `${varient === "vertical" ? "text-[20px]" : "absolute text-[18px]"} ` : 'static'} ${ disabled ? 'text-disable-200' :
            isFocused
              ? 'text-primary'
              : error
              ? 'text-red-600'
              : value !== 'undefined' && value !== ''
              ? 'text-primary'
              : 'text-secondary'
          } ${labelClassName}`}
        >
          {label}
        </label>
      ) : null}

      <img className='w-[23px] absolute right-[12px] top-[18px] bg-white z-[10]' onClick={() => datepicker.current.focus()} src={CalenderIcon}/>
      <input
        name={name}
        value={value}
        disabled={disabled}
        ref={datepicker}
        id={name}
        type="text"
        className={`w-full p-3 mt-2 text-lg border px-4 rounded ${
          isFocused
            ? 'border-primary focused'
            : disabled
            ? 'text-disable-200 bg-none border-disable'
            : error
            ? 'border-red-700'
            : 'border-secondary-200'
        } outline-none ${className}`}
        onBlur={() => {
          setTimeout(() => {
            setIsFocused(false);
          }, 100);
        }}
        {...restProps}
      />
      {error && !disabled ? <p className="text-red-600 mx-2 pl-2">{error}</p> : null}
    </div>
  );
};

export default Datepicker;
